<template>
  <div class="layoutContainer">
    <div class="secondFloat">
      <el-form :inline="true" ref="formInline" :model="formInline" class="demo-form-inline">
        <el-form-item label="项目名称" prop="projectName">
          <el-input v-model="formInline.projectName" placeholder="请输入项目名称"></el-input>
        </el-form-item>
        <el-form-item label="负责人" prop="principal">
          <el-select v-model="formInline.principal">
            <el-option v-for="(item, index) of principalList" :key="index" :label="item.realName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手册状态" prop="manualStatus">
          <el-select v-model="formInline.manualStatus">
            <el-option v-for="(item, index) of manualList" :key="index" :label="item.name" :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="节点时间">
          <el-date-picker type="date" placeholder="选择开始日期" v-model="formInline.begintime"></el-date-picker>
          <span class="line">至</span>
          <el-date-picker type="date" placeholder="选择结束日期" v-model="formInline.endtime"></el-date-picker>
        </el-form-item>
        <el-form-item label="风险状态" prop="riskStatus">
          <el-select v-model="formInline.riskStatus">
            <el-option v-for="(item, index) of riskList" :key="index" :label="item.name" :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-search">查询</el-button>
          <el-button plain @click="reset('formInline')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableDetail">
      <el-button type="primary" icon="el-icon-plus" @click="handelAdd()">新增</el-button>
      <el-table style="width:100%" border :data="resultList" :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">
        <el-table-column label="项目名称" prop="projectName" width="180"></el-table-column>
        <el-table-column label="手册分类" prop="cnName" width="180"></el-table-column>
        <el-table-column label="负责人" prop="principalName" width="180"></el-table-column>
        <el-table-column label="开发状态" prop="manualStatus" width="110">
          <template slot-scope="{row}">
            <span v-if="row.manualStatus === 1">进行中</span>
            <span v-if="row.manualStatus === 2">暂停中</span>
            <span v-if="row.manualStatus === 3">已验收</span>
          </template>
        </el-table-column>
        <el-table-column label="节点时间" prop="deadlineTime" width="180" :formatter='dateFormat'></el-table-column>
        <el-table-column label="初稿时间" prop="firstDraftTime" width="180" :formatter='dateFormat'></el-table-column>
        <el-table-column label="终稿时间" prop="finalDraftTime" width="180" :formatter='dateFormat'></el-table-column>
        <el-table-column label="风险状态" prop="riskStatus" width="110">
          <template slot-scope="{row}">
            <span v-if="row.riskStatus === 1" style="color:#009933">无风险</span>
            <span v-if="row.riskStatus === 2" style="color:#cc0000">低风险</span>
            <span v-if="row.riskStatus === 3" style="color:#cc0000">中风险</span>
            <span v-if="row.riskStatus === 4" style="color:#cc0000">高风险</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="350">
          <template slot-scope="{row}">
            <el-button type="primary" size="mini" @click="manage(row, '数据管理', '/manage')">数据管理</el-button>
            <el-button type="primary" size="mini" @click="catalog(row, '目录管理', '/catalog')">手册目录</el-button>
            <el-button type="primary" size="mini" @click="content(row, '内容管理', '/content')">手册内容</el-button> 
          
            <!-- <el-button type="primary" size="mini" @click="version(row, '手册版本', '/version')">数据管理</el-button> -->
            <el-dropdown>
              <el-button type="primary">
                更多<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="handelDetail(row)">查看</el-dropdown-item>
                <el-dropdown-item @click.native="handelEdit(row)">编辑</el-dropdown-item>
                <el-dropdown-item @click.native="handelDelete(row)">删除</el-dropdown-item>
                <el-dropdown-item @click.native="version(row, '手册版本', '/version')">版本</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total>0" :total="total" :page.sync="currentPage" :limit.sync="pagesize" @pagination="dataList"/>
      <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" :before-close="handleCloseCreate">
        <el-form v-if="dialogStatus === 'detail'" ref='temp' :model="temp" label-position="center">
          <el-form-item label="项目名称" prop="projectName" :label-width="formLabelWidth">
            <el-input v-model="temp.projectName" readonly></el-input>
          </el-form-item>
          <el-form-item label="手册分类" prop="cnName" :label-width="formLabelWidth">
            <el-input v-model="temp.cnName" readonly></el-input>
          </el-form-item>
          <el-form-item label="负责人" prop="principalName" :label-width="formLabelWidth">
            <el-input v-model="temp.principalName" readonly></el-input>
          </el-form-item>
          <el-form-item label="开发状态" prop="manualStatus" :label-width="formLabelWidth">
            <el-input v-if="temp.manualStatus === 1" readonly v-model="manualList[0].name"></el-input>
            <el-input v-if="temp.manualStatus === 2" readonly v-model="manualList[1].name"></el-input>
            <el-input v-if="temp.manualStatus === 3" readonly v-model="manualList[2].name"></el-input>
          </el-form-item>
          <el-form-item label="节点时间" prop="deadlineTime" :label-width="formLabelWidth">
            <el-date-picker type="date" v-model="temp.deadlineTime" readonly></el-date-picker>
          </el-form-item>
          <el-form-item label="初稿时间" prop="firstDraftTime" :label-width="formLabelWidth">
             <el-date-picker type="date"  v-model="temp.firstDraftTime" readonly></el-date-picker>
          </el-form-item>
          <el-form-item label="终稿时间" prop="finalDraftTime" :label-width="formLabelWidth">
            <el-date-picker type="date"  v-model="temp.finalDraftTime" readonly></el-date-picker>
          </el-form-item>
          <el-form-item label="风险状态" prop="riskStatus" :label-width="formLabelWidth">
            <el-input v-if="temp.riskStatus === 1" v-model="riskList[0].name"  style="color:#009933" readonly>无风险</el-input>
            <el-input v-if="temp.riskStatus === 2" v-model="riskList[1].name"  style="color:#cc0000" readonly>低风险</el-input>
            <el-input v-if="temp.riskStatus === 3" v-model="riskList[2].name"  style="color:#cc0000" readonly>中风险</el-input>
            <el-input v-if="temp.riskStatus === 4" v-model="riskList[3].name"  style="color:#cc0000" readonly>高风险</el-input>   
          </el-form-item>
        </el-form>
        <el-form v-if="dialogStatus === 'add' || dialogStatus === 'edit'" ref='temp' :model="temp" :rules="rules" label-position="center">
          <el-form-item label="项目名称" prop="projectId" :label-width="formLabelWidth">
            <el-select v-model="temp.projectId">
              <el-option v-for="(item, index) in projectList" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="手册分类" prop="manualType" :label-width="formLabelWidth">
            <el-select v-model="temp.manualType">
              <el-option v-for="(item, index) in manualTypeList" :key="index" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="负责人" prop="principal" :label-width="formLabelWidth">
            <el-select v-model="temp.principal">
              <el-option v-for="(item, index) of principalList" :key="index" :label="item.realName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开发状态" prop="manualStatus" :label-width="formLabelWidth">
            <el-select v-model="temp.manualStatus">
              <el-option v-for="(item, index) of manualList" :key="index" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="节点时间" prop="deadlineTime" :label-width="formLabelWidth">
            <el-date-picker type="date" v-model="temp.deadlineTime" placeholder="请选择节点时间"></el-date-picker>
          </el-form-item>
          <el-form-item label="初稿时间" prop="firstDraftTime" :label-width="formLabelWidth">
            <el-date-picker type="date" v-model="temp.firstDraftTime" placeholder="请选择初稿时间"></el-date-picker>
          </el-form-item>
          <el-form-item label="终稿时间" prop="finalDraftTime" :label-width="formLabelWidth">
            <el-date-picker type="date" v-model="temp.finalDraftTime" placeholder="请输入终稿时间"></el-date-picker>
          </el-form-item>
          <el-form-item label="风险状态" prop="riskStatus" :label-width="formLabelWidth">
            <el-select v-model="temp.riskStatus">
              <el-option v-for="(item, index) of riskList" :key="index" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <el-button type="primary" @click="dialogStatus === 'add' ? addClick('temp') : updateData('temp')">
              立即提交
            </el-button>
            <el-button @click="resetForm('temp')">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { sysServerUrl, cmsServerUrl, obj } from '@/assets/js/common.js'
import Pagination from '@/components/Pagination'
import { manualData, manualSearch, manualUser, projectList, manualType, manualAdd, manualEdit, manualDel } from '@/api/api.js'
import axios from 'axios'
export default {
  name: 'layoutContainer',
  components: { Pagination },
  data () {
    return {
      formInline: {
        projectName: '',
        principal: '',
        manualStatus: '',
        begintime: '',
        endtime: '',
        riskStatus: ''
      },
      temp: {
        id: '',
        projectId: '',
        projectName: '',
        cnName: '',
        manualType: '',
        principalName: '',
        principal: '',
        manualStatus: '',
        deadlineTime: '',
        firstDraftTime: '',
        finalDraftTime: '',
        riskStatus: ''
      },
      manualList: [
        { name: '未开始', code: 1 },
        { name: '进行中', code: 2 },
        { name: '已完成', code: 3 }
      ],
      riskList: [
        { name: '无风险', code: 1 },
        { name: '低风险', code: 2 },
        { name: '中风险', code: 3 },
        { name: '高风险', code: 4 }
      ],
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        detail: '手册明细',
        edit: '编辑手册',
        add: '新增手册'
      },
      principalList: [],
      resultList: [],
      pagesize: 10,
      currentPage: 1,
      total: 0,
      projectList: [],
      manualTypeList: [],
      formLabelWidth: '100px',
      rules: {
        projectId: [{ required: true, message: '项目名称不能为空', trigger: 'blur' }],
        manualType: [{ required: true, message: '手册分类不能为空', trigger: 'blur' }],
        principal: [{ required: true, message: '负责人不能为空', trigger: 'blur' }],
        manualStatus: [{ required: true, message: '开发状态不能为空', trigger: 'blur' }],
        deadlineTime: [{ required: true, message: '节点时间不能为空', trigger: 'blur' }],
        firstDraftTime: [{ required: true, message: '初稿时间不能为空', trigger: 'blur' }],
        finalDraftTime: [{ required: true, message: '终稿时间不能为空', trigger: 'blur' }],
        riskStatus: [{ required: true, message: '风险状态不能为空', trigger: 'blur' }]
      }
    }
  },
  methods: {
    // 数据
    dataList () {
      // var params = '?page=' + this.currentPage + '&limit=' + this.pagesize
      var params= {
        page: this.currentPage,
        limit: this.pagesize
      }
      manualData(params).then(res=>{
        this.total = res.data.total
        this.resultList = res.data.data
      })
    },
    // 搜索
    onSubmit () {
      var params = {
        page: this.currentPage,
        limit: this.pagesize,
        projectName: this.formInline.projectName,
        principal: this.formInline.principal,
        manualStatus: this.formInline.manualStatus,
        firstDraftTime: this.formInline.begintime,
        finalDraftTime: this.formInline.endtime,
        riskStatus: this.formInline.riskStatus
      }
      manualSearch(params).then(res => {
        this.total = res.data.total
        this.resultList = res.data.data
      })
    },
    // 获取用户列表
    getUserList () {
      manualUser().then(res => {
         if (res.data.code === 100) {
          this.principalList = res.data.data
        }
      })
    },
    // 获取项目列表
    getProjectList () {
      projectList().then(res => {
        if (res !== null && res.data.code === 100) {
          this.projectList = res.data.data
        }
      })
    },
    // 获取手册分类
    getManualList () {
      manualType().then(res => {
        if (res !== null && res.data.code === 100) {
          this.manualTypeList = res.data.data
        }
      })
    },
    handleCloseCreate () {
      this.dialogFormVisible = false
      this.$refs.temp.resetFields()
    },
    resetTemp () {
      this.temp = {
        id: '',
        projectName: '',
        cnName: '',
        principal: '',
        manualStatus: '',
        deadlineTime: '',
        firstDraftTime: '',
        finalDraftTime: '',
        riskStatus: ''
      }
    },
    reset (formInline) {
      if (this.$refs[formInline].resetFields() !== undefined) {
        this.$refs[formInline].resetFields()
      }
      this.dataList()
    },
    // 手册分类
    updatedCode () {
      for (var i = 0; i < this.manualTypeList.length; i++) {
        if (this.manualTypeList[i].code === this.temp.manualType) {
          this.temp.cnName = this.manualTypeList[i].name
        }
      }
    },
    // 新增
    handelAdd () {
      this.resetTemp()
      this.dialogFormVisible = true
      this.dialogStatus = 'add'
    },
    addClick (temp) {
      this.$refs[temp].validate((valid) => {
        if(valid){
          var params = new URLSearchParams()
          this.updatedCode()
          params.append('projectId', this.temp.projectId)
          params.append('cnName', this.temp.cnName)
          params.append('manualType', this.temp.manualType)
          params.append('principal', this.temp.principal)
          params.append('deadlineTime', obj.getMyDate(this.temp.deadlineTime))
          params.append('firstDraftTime', obj.getMyDate(this.temp.firstDraftTime))
          params.append('finalDraftTime', obj.getMyDate(this.temp.finalDraftTime))
          params.append('riskStatus', this.temp.riskStatus)
          manualAdd(params).then(res => {
            if (res.data.code === 100) {
              this.$message({
                message: res.data.msg,
                type: 'success'
              })
              this.dataList()
              this.dialogFormVisible = false
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }else{
          this.$message.error('请完善手册信息')
        }
      })
    },
    // 查看
    handelDetail (row) {
      this.resetTemp()
      this.temp = Object.assign({}, row)
      this.dialogFormVisible = true
      this.dialogStatus = 'detail'
    },
    // 编辑
    handelEdit (row) {
      this.temp = Object.assign({}, row)
      this.dialogFormVisible = true
      this.dialogStatus = 'edit'
    },
    updateData (temp) {
      this.$refs[temp].validate((valid) => {
        if(valid){
          this.updatedCode()
          var params = new URLSearchParams()
          params.append('id', this.temp.id)
          params.append('projectId', this.temp.projectId)
          params.append('cnName', this.temp.cnName)
          params.append('manualType', this.temp.manualType)
          params.append('principal', this.temp.principal)
          params.append('manualStatus', this.temp.manualStatus)
          params.append('deadlineTime', obj.getMyDate(this.temp.deadlineTime))
          params.append('firstDraftTime', obj.getMyDate(this.temp.firstDraftTime))
          params.append('finalDraftTime', obj.getMyDate(this.temp.finalDraftTime))
          params.append('riskStatus', this.temp.riskStatus)
          manualEdit(params).then(res => {
            if (res.data.code === 100) {
              this.$message({
                message: res.data.msg,
                type: 'success'
              })
              this.dataList()
              this.dialogFormVisible = false
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }else{
          this.$message.error('请完善手册信息')
        }
      })
    },
    resetForm (temp) {
      if (this.$refs[temp].resetFields() !== undefined) {
        this.$refs[temp].resetFields()
      }
    },
    // 删除
    handelDelete (row) {
      this.$confirm('确定删除【项目： ' + row.projectName +'】中的【'+ row.cnName + '】?', '删除手册', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        manualDel(row.id).then(res => {
          if (res.data.code === 100) {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.dataList()
          }
        })
      }).catch((error)=>{
        this.$message.error('删除失败')
      })
    },
    // 手册版本
    version (row, name, url) {
      var curl = url + "/" + row.id 
      obj.tabsClick(name, curl)
      this.$router.push({ name: 'version', params: { id: row.id } })
    },
    // 手册目录
    catalog (row, name, url) {
      let title =row.projectName+"#"+row.cnName+"#"+name
      let curl= url+'/'+row.id+'/'+row.manualVersion
      obj.tabsClick(title, curl)
      this.$router.push({ name: 'catalog',params: {id: row.id, manualVersion: row.manualVersion } })
    },
    // 手册内容
    content (row, name, url) {
      let title =row.projectName+"#"+row.cnName+"#"+name
      let curl= url+'/'+row.id+'/'+row.manualVersion
      obj.tabsClick(title, curl)
      this.$router.push({ name: 'content', params: {id: row.id, manualVersion: row.manualVersion } })
    },
    // 数据管理
    manage (row, name, url) {
      var curl = url + '/' + row.id
      obj.tabsClick(name, curl)
      this.$router.push({ name: 'manage' ,params: { id: row.id }})
    },
    dateFormat (row, column, cellValue, index) {
      if (cellValue !== null) {
        const date = new Date(parseInt(cellValue))
        const Y = date.getFullYear() + '-'
        const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
        const D = date.getDate() < 10 ? '0' + date.getDate() + '' : date.getDate() + ''
        return Y + M + D
      }
    }
  },
  mounted () {
    this.dataList()
    this.getUserList()
    this.getProjectList()
    this.getManualList()
  }
}
</script>
